// ==============================
// Back to top
// =============================

.back-to-top {
  display: none;
  position: fixed;
  right: 20px;
  bottom: 20px;
  transition-property: transform;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;
  z-index: 10;

  &:hover {
    transform: translateY(-5px); 
  }
}

@include max-screen() {
  .back-to-top {
    display: none !important;
  }
}