// ==============================
// Header
// ==============================

.header {
  @include clearfix; 
  padding: $header-padding;

  @import '_header/logo';
  @import '_header/menu';
}


@include max-screen() {
  .header {
    padding: 50px 0 0;
    text-align: center;
  }
}
