// ==============================
// slideout (https://github.com/mango/slideout)
// ==============================

.slideout-menu {
  position: fixed;
  top: 0;
  left: 0px;
  bottom: 0;
  width: 180px;
  min-height: 100vh;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  display: none;
}

.slideout-panel {
  position: relative;
  z-index: 1;
  background-color: $white;
  min-height: 100vh;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  display: block;
}
