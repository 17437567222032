.post-reward {
  margin-top: 20px;
  padding-top: 10px;
  text-align: center;
  border-top: 1px dashed $light-gray;

  .reward-button {
    margin: 15px 0;
    padding: 3px 7px;
    display: inline-block;
    color: $theme-color;
    border: 1px solid $theme-color;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      color: $white;
      background-color: $theme-color;
      transition: 0.5s;
    }
  }

  #reward:checked {
    & ~ .qr-code {
      display: block;
    }

    & ~ .reward-button {
      display: none;
    }
  }

  .qr-code {
    display: none;

    .qr-code-image {
      display: inline-block;
      min-width: 200px;
      width: 40%;
      margin-top: 15px;

      span {
        display: inline-block;
        width: 100%;
        margin: 8px 0;
      }
    }

    .image {
      width: 200px;
      height: 200px;
    }
  }
}