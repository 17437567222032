// ==============================
// Social
// =============================

.social-links {
  text-align: center;

  .iconfont {
    font-size: $social-icon-font-size;

    & + .iconfont {
      margin-left: $social-link-margin-left;
    } 

    &:hover {
      color: $theme-color;
    }
  }
}